<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item label="订单编号" prop="id" class="hide" >
        <a-input v-model="form.id"  readOnly  />
      </a-form-model-item>
      <a-form-model-item label="姓名/公司名称" prop="subjectName" class="hide" >
        <a-input v-model="form.subjectName"  readOnly  placeholder="请输入姓名/公司名称" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="contactPhone"  class="hide">
        <a-input v-model="form.contactPhone"  readOnly  placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="预计人数" prop="personNum"  class="hide">
        <a-input v-model="form.personNum"  readOnly  placeholder="请输入预计人数" />
      </a-form-model-item>
      <a-form-model-item label="活动主题" prop="theme"  class="hide" >
        <a-input v-model="form.theme"  readOnly  placeholder="请输入活动主题" />
      </a-form-model-item>
      <a-form-model-item label="单价" prop="price"  class="hide" >
        <a-input v-model="form.price"  readOnly  placeholder="请输入单价" />
      </a-form-model-item>
      <a-form-model-item label="总天数" prop="totalDay"  class="hide" >
        <a-input v-model="form.totalDay"  readOnly  placeholder="请输入总天数" />
      </a-form-model-item>
      <a-form-model-item label="订单金额" prop="orderAmount"  class="hide" >
        <a-input v-model="form.orderAmount"  readOnly  placeholder="请输入订单价格 = 单价*总天数" />
      </a-form-model-item>
      <a-form-model-item label="应付款" prop="payAmount"  class="hide" >
        <a-input v-model="form.payAmount"  readOnly  placeholder="请输入应付款 " />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            确认支付
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getSiteOrderDetail, paySite} from '@/api/order/site'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'PayForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        outTradeNo: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        outTradeNo: null,
        remark: null,
      }
    },
    /** 审核按钮操作 */
    handlePay(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSiteOrderDetail({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '确认支付'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            paySite(this.form).then(response => {
              this.$message.success(
                '支付成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.hide {
  /deep/ .ant-input {
    border: 0 !important;
  }
}
</style>
