import { render, staticRenderFns } from "./PayForm.vue?vue&type=template&id=fb9af5ba&scoped=true"
import script from "./PayForm.vue?vue&type=script&lang=js"
export * from "./PayForm.vue?vue&type=script&lang=js"
import style0 from "./PayForm.vue?vue&type=style&index=0&id=fb9af5ba&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb9af5ba",
  null
  
)

export default component.exports